<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <div class="row mb-4">
                  <div class="col-md-12">
                     <img
                        class="mx-auto d-block"
                        name="logo"
                        size="custom-size"
                        :height="66"
                        viewBox="0 0 556 134"
                        src="/cwasa-hd-logo.png"
                        alt="CWASA Logo"
                      />
                    <h4 class="mt-2" style="text-align: center;color: rgb(19 23 150); font-size: 37px; font-weight: bold;">স্মার্ট ওয়াটার মিটারিং সিস্টেম</h4>
                  </div>
                </div>
                <h1>Login</h1>
                <p class="text-muted">Sign In to your account</p>
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(doLogin)">
                    <div v-if="serverErrors">
                      <CAlert
                        color="danger"
                        v-for="err in serverErrors"
                        :key="err"
                      >
                        {{ err }}.
                      </CAlert>
                    </div>
                    <ValidationProvider
                      name="Email"
                      :rules="{ required: true, email: true }"
                      v-slot="{ errors }"
                    >
                      <CInput
                        placeholder="Email"
                        v-model="user.email"
                        type="email"
                        :addInputClasses="errors.length ? 'is-invalid' : ''"
                      >
                        <template #prepend-content
                          ><CIcon name="cil-user"
                        /></template>
                      </CInput>
                      <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
                      <p
                        class="text-danger mt-0 pt-0"
                        v-if="fieldErrors && fieldErrors.email"
                      >
                        {{ fieldErrors.email[0] }}
                      </p>
                    </ValidationProvider>

                    <ValidationProvider
                      name="Password"
                      :rules="{ required: true, min: 6 }"
                      v-slot="{ errors }"
                    >
                      <CInput
                        placeholder="Password"
                        v-model="user.password"
                        type="password"
                        :addInputClasses="errors.length ? 'is-invalid' : ''"
                      >
                        <template #prepend-content
                          ><CIcon name="cil-lock-locked"
                        /></template>
                      </CInput>
                      <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
                      <p
                        class="text-danger mt-0 pt-0"
                        v-if="fieldErrors && fieldErrors.password"
                      >
                        {{ fieldErrors.password[0] }}
                      </p>
                    </ValidationProvider>

                    <CRow v-if="appEnv == 'production'">
                      <CCol class="text-left pb-2">
                        <vue-recaptcha
                          :sitekey="siteKey"
                          :loadRecaptchaScript="true"
                          ref="recaptcha"
                          type="invisible"
                          @verify="onCaptchaVerified"
                          @expired="onCaptchaExpired"
                        >
                        </vue-recaptcha>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol col="6" class="text-left">
                        <CButton color="primary" type="submit" class="px-4"
                          >Login</CButton
                        >
                      </CCol>
                      <CCol col="6" class="text-right">
                        <router-link :to="{ name: 'ForgotPassword' }"
                          >Forgot password?</router-link
                        >
                        <!-- <CButton color="link" class="px-0"
                          >Forgot password?</CButton
                        > -->
                      </CCol>
                    </CRow>
                  </form>
                </ValidationObserver>
              </CCardBody>
            </CCard>
            <!-- <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard> -->
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import cookieHelper from "../../helpers/cookieHelper";
export default {
  name: "LoginComponent",

  data() {
    return {
      fieldErrors: {},
      user: {
        email: "",
        password: "",
        recaptcha: "",
      },
      serverErrors: [],
      submitted: false,
      loadingPage: true,
      siteKey: `${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`,
      appEnv: `${process.env.VUE_APP_ENV}`
    };
  },

  components: {
    //
    "vue-recaptcha": VueRecaptcha,
  },
  methods: {
    doLogin() {
      //   this.$root.$emit("showLoadingSpinner");

      if (!cookieHelper.getCookie("dv_uuid")) {
        cookieHelper.setCookie("dv_uuid", this.uuidv4(), 672); //28 days
      }

      this.$auth.login({
        data: {
          email: this.user.email,
          password: this.user.password,
          device_uuid: cookieHelper.getCookie("dv_uuid"),
          recaptcha: this.user.recaptcha
        },
        success: function () {
          //   this.$root.$emit("stopLoadingSpinner");
          window.localStorage.setItem(
            "currentUser",
            JSON.stringify(this.$auth.user())
          );
          this.loadingPage = false;
          if (this.appEnv == 'production'){
            this.onCaptchaExpired();
          }
        },
        error: function (err) {
          this.serverErrors = [];
          if (err.response.data) {
            this.serverErrors.push(err.response.data.message);
            this.fieldErrors = err.response.data.errors;
          }
          this.loadingPage = false;
          this.$root.$emit("stopLoadingSpinner");
          this.onCaptchaExpired();
        },
        rememberMe: true,
        fetchUser: true,
        redirect: "/dashboard",
      });
    },

    handleErrors(Errs) {
      const newErr = [];
      _.forEach(Errs, function (value, key) {
        newErr.push(Errs[key][0]);
      });
      this.serverErrors = newErr;
    },

    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    onCaptchaVerified(recaptchaToken) {
      this.user.recaptcha = recaptchaToken;
      this.validateCaptcha = true;
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
  },
  created() {
    if (this.$auth.ready() && this.$auth.check()) {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.loadingPage = false;
  },
};
</script>
